.Intro {
	position: fixed;
	top: 10%;
	left: 10%;
	text-align: left;
	color: white;
	align-items: start;
	justify-content: center;
	display: flex;
	flex-direction: column;
	border-radius: 30px;
	transition-duration: 0.5s;
}
.IntroContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.IntroCover {
	align-self: flex-end;
	animation: swipe 3s ease 1 forwards; /* added the 'forwards' value here */
	background-color: #0e172e;
	width: 50vw;
	margin-right: 5vw;
	height: 20vh;
	margin-top: -20vh;
}

@keyframes swipe {
	0% {
		width: 25vw;
	}
	100% {
		width: 0vw;
	}
}

.IntroTitle {
	align-self: flex-start;
	margin-bottom: 2%;
	font-size: 2.5vw;
	font-weight: 700;
}

.Summary {
	margin-left: 0.4%;
	align-self: flex-start;
	color: #92a0b6;
	margin-top: 1vh;
	font-size: 1vw;
	width: 70%;
	border-radius: 20px;
}

@media screen and (max-width: 900px) {
	.Intro {
		top: 5%;
		left: 25%;
	}
	.IntroTitle {
		font-size: 7vw;
	}
	.Summary {
		font-size: 3vw;
	}
	@keyframes swipe {
		0% {
			width: 100vw;
		}
		100% {
			width: 0vw;
		}
	}

	.IntroCover {
		position: fixed;
		top: 10%;
		left: 10%;
		align-self: flex-end;
		background-color: #0e172e;
		width: 100vw;
		height: 40vh;
		margin-right: -100vw;
	}
}
