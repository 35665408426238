.MainPage {
	display: flex;
	text-align: center;
	align-items: start;
	justify-content: center;
	min-height: 100vh;
	height: 100vh;
	overflow-y: hidden;
	width: 100vw;
	background-color: #0e172e;
	filter: blur(0px);
}

@keyframes blurFadeOut {
	0% {
		filter: blur(0) opacity(1);
	}

	100% {
		filter: blur(50px) opacity(0);
	}
}

@keyframes Links {
	0% {
		filter: opacity(0);
	}
	30% {
		filter: opacity(0);
	}
	100% {
		filter: opacity(1);
	}
}
.LinkContainer {
	animation: Links 5s ease 1 forwards; /* added the 'forwards' value here */
	text-align: left;
	color: white;
	position: fixed;
	top: 90%;
	left: 10%;
}
.GitHubLogo {
	width: 15%;
}
.GitHubLogo:hover {
	cursor: pointer;
}
.MPColumn {
	overflow-y: auto;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
}
.ProjectContainer {
	width: 50%;
	margin-left: 50%;
	margin-top: 30%;
	display: flex;
	flex-direction: column;
	row-gap: 0;
	column-gap: 0;
}

.Title {
	align-self: center;
	justify-self: center;
	width: fit-content;
	margin: 2vh;
	color: #ffffff;
	font-weight: 500;
	font-size: 200%;
	padding: 5px;
	border-radius: 8px;
}

@media screen and (max-width: 900px) {
	.MPColumn {
		margin-top: 35%;
		display: flex;
		align-items: center;
		justify-content: start;
	}
	.ProjectContainer {
		margin-top: 0%;
		height: 100%;
		width: 100%;
		margin-left: 0%;
		display: flex;
		align-items: center;
		justify-content: end;
		flex-direction: column;
	}
	.Container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 50%;
	}

	.LinkContainer {
		text-align: left;
		color: white;
		position: fixed;
		top: 5%;
	}
}
