.test {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: fit-content;
	background-color: #0e172e;
	justify-content: center;
	font-family: "Roboto", sans-serif;
	transition-duration: 100ms;
	animation: blurFade 1000ms forwards; /* 500ms duration */
}
@keyframes blurFade {
	from {
		filter: blur(100px) opacity(0);
	}
	to {
		filter: blur(0) opacity(1);
	}
}

.testTitle {
	color: #ffffff;
}
.testMain {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: -5%;
}
.testTagsContainer {
}

.testButtonContainer {
	display: flex;
	justify-content: right;
	margin: 3vh;
	padding-right: 10%;
}

.testText {
	width: 30%;
	text-align: left;
	align-self: center;
	margin: 5%;

	font-size: 175%;
	color: #c5cdd9;
}

.testVideo {
	display: flex;
	align-items: center;
	justify-content: center;
}

.testColumnContainer {
	display: flex;
	justify-content: center;
}

.testColumn {
	margin: 10px;
	width: 40vw;
	height: fit-content;
}

.testColumnButton {
	background-color: transparent;
	color: #5adfcb;
	border: 1px solid #5adfcb;
	background-color: #122b39;
	padding: 10px 20px;
	margin: 1%;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 200%;
	transition-duration: 0.4s;

	cursor: pointer;
}

.testColumnButton:hover {
	background-color: #5adfcb;
	color: #121212;
}

.testColumnTitle {
	font-size: 200%;
	color: #ffffff;
}
.testColumnTextContainer {
	background-color: #1e1e1e00;
	padding-bottom: 2%;
	padding-left: 2%;
	padding-right: 2%;
}
.testColumnTextSubTitle {
	color: #ffffff;
	font-size: 150%;
	font-weight: 900;
	margin-top: 30px;
	margin-bottom: 2%;
}

.testColumnText {
	text-align: left;
	font-size: 125%;
	color: #92a0b6;
}

@media screen and (max-width: 800px) {
	#YoutubeVideo {
		width: 70vw;
		height: 40vw;
	}
	.testMain {
		flex-direction: column;
	}
	.testText {
		width: 70%;
		font-size: 120%;
	}
	.testButtonContainer {
		justify-content: center;
		margin: 10%;
		padding-right: 0%;
	}
	.testColumnContainer {
		flex-direction: column;
		align-items: center;
	}
	.testColumn {
		margin: 10px;
		width: 80vw;
	}
}
