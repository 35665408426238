.project {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	width: 70%;
	height: fit-content;
	margin: 3%;
	border-color: transparent;
	border-width: 0.1vw;
	border-radius: 16px;
	padding: 0.5%;
	border-style: solid;
	color: white;
	background-color: #1e1e1e00;
	transition-duration: 0.5s;
	animation: fadein 2s ease 1 forwards;
}
.project:hover {
	cursor: pointer;
	background-color: #212e4f;
	border-color: #2d3c60;
	box-shadow: #fbfbfb 5px 5px;
}

@keyframes fadein {
	0% {
		filter: opacity(0) blur(10px);
	}
	100% {
		filter: opacity(1) blur(0);
	}
}

@keyframes blurFade {
	from {
		filter: blur(0px);
	}
	to {
		filter: blur(1000px);
	}
}

.Columns {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;
}

.ImageColumn {
	width: 50%;
	height: 100%;
}

.InfoColumn {
	width: 50%;
	height: 100%;
}

.ProjectTitle {
	font-size: 1.75vw;
}

.ProjectImage {
	border-radius: 3%;
	margin-top: 3%;
	width: 100%;
	height: 90%;
}

.ProjectDescription {
	color: #92a0b6;
	font-size: 0.8vw;
	margin: 5%;
	text-align: left;
}

.TagContainer {
	display: flex;
	align-items: center;
	justify-content: start;
	flex-wrap: wrap;
}
.tag {
	border-color: transparent;
	color: #5adfcb;
	font-size: 0.8vw;
	padding: 2%;
	margin: 1%;
	background-color: #122b39;
	border-width: 1px;
	border-radius: 1vw;
	border-style: solid;
	width: fit-content;
}

@media screen and (max-width: 900px) {
	.project {
		width: 100%;
	}
	.Columns {
		text-align: left;
		flex-direction: column-reverse;
		align-items: center;
	}

	.ImageColumn {
		width: 90%;
	}

	.InfoColumn {
		width: 90%;
	}

	.ProjectTitle {
		font-size: 5vw;
	}

	.ProjectDescription {
		color: #92a0b6;
		font-size: 4vw;
		margin: 5%;
		text-align: left;
	}
	.tag {
		font-size: 3.5vw;
	}
}
